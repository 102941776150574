<template>
  <q-page class="container staticPage customerSupportPage">
    <div class="row q-col-gutter-lg">
      <div class="col-12 col-md-5 order-md-last" v-if="isFormVisible">
        <CustomerSupportForm />
      </div>
      <div
        class="col-12 order-md-first"
        :class="isFormVisible ? 'col-md-7' : 'col-md-8 offset-md-2'"
      >
        <div class="row q-col-gutter-lg">
          <div class="col-12 col-md-12" v-if="!isMobile">
            <div class="breadcrumb-back">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
            </div>
            <h1 class="title-h1">Customer Support</h1>
          </div>
          <div class="col-12 col-md-12">
            <div
              class="content"
              v-html="businessConfigurations.customerSupportDescription"
            />
          </div>
          <div class="col-12 col-md-6">
            <a
              :href="`mailto:${businessConfigurations.customerServiceEmail}?subject=Customer Support`"
              class="r-5 flex no-wrap justify-start items-center btn-border"
            >
              <q-avatar
                size="md"
                color="secondary"
                text-color="white"
                icon="email"
                class="q-mr-md"
              />
              <span class="text-dark column word-break-word">
                <span class="text-caption">Email us at</span>
                <span class="text-body1">{{
                  businessConfigurations.customerServiceEmail
                }}</span>
              </span>
            </a>
          </div>
          <div class="col-12 col-md-6">
            <a
              :href="`tel:${businessConfigurations.customerServicePhone}`"
              class="r-5 flex no-wrap justify-start items-center btn-border"
            >
              <q-avatar
                size="md"
                color="secondary"
                text-color="white"
                icon="phone"
                class="q-mr-md"
              />
              <span class="text-dark column word-break-word">
                <span class="text-caption">Call on</span>
                <span class="text-body1">{{
                  businessConfigurations.customerServicePhone
                }}</span>
              </span>
            </a>
          </div>
        </div>

        <div class="row q-col-gutter-lg q-pt-lg items-stretch justify-start">
          <div v-if="hasAvailablePolicies" class="col-12 col-md-12">
            <h2 class="title-h1">Read our policies</h2>
          </div>
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enablePrivacyPolicy &&
              businessConfigurations.privacyPolicy
            "
          >
            <q-card flat class="full-height contentBox">
              <q-card-section>
                <q-avatar size="48px" color="secondary" text-color="white">
                  <SvgIcon icon="privacy-policy" />
                </q-avatar>
                <h4 class="q-ma-none"> Privacy Policy </h4>
                <q-btn
                  unelevated
                  no-caps
                  outline
                  rounded
                  color="secondary"
                  size="14px"
                  label="Read More"
                  class="no-hover"
                  to="/pages/legal/privacy-policy"
                />
              </q-card-section>
            </q-card>
          </div>
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enableRefundPolicy &&
              businessConfigurations.refundPolicy
            "
          >
            <q-card flat class="full-height contentBox">
              <q-card-section>
                <q-avatar size="48px" color="secondary" text-color="white">
                  <SvgIcon color="white" icon="refund-policy" />
                </q-avatar>
                <h4 class="q-ma-none"> Refund Policy </h4>
                <q-btn
                  unelevated
                  no-caps
                  outline
                  rounded
                  color="secondary"
                  size="14px"
                  label="Read More"
                  class="no-hover"
                  to="/pages/legal/refund-policy"
                />
              </q-card-section>
            </q-card>
          </div>
          <div
            class="col-12 col-md-4"
            v-if="
              businessConfigurations.enableCustomerReviewPolicy &&
              businessConfigurations.customerReviewPolicy
            "
          >
            <q-card flat class="full-height contentBox">
              <q-card-section>
                <q-avatar size="48px" color="secondary" text-color="white">
                  <SvgIcon icon="review-policy" />
                </q-avatar>
                <h4 class="q-ma-none"> Review Policy </h4>
                <q-btn
                  unelevated
                  no-caps
                  outline
                  rounded
                  color="secondary"
                  size="14px"
                  label="Read More"
                  class="no-hover"
                  to="/pages/legal/review-policy"
                />
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'CustomerSupport',
  meta() {
    return {
      title: 'Customer Support',
      ...this.commonMeta,
    }
  },
  components: {
    CustomerSupportForm: () =>
      import('src/components/customer-support/form.vue'),
  },
  computed: {
    isFormVisible() {
      return (
        this.isLoggedIn ||
        !!this.businessConfigurations?.isCustomerInquiryForRegisteredUser
      )
    },
    hasAvailablePolicies() {
      const {
        enablePrivacyPolicy,
        enableRefundPolicy,
        enableCustomerReviewPolicy,
      } = this.businessConfigurations
      return (
        enablePrivacyPolicy || enableRefundPolicy || enableCustomerReviewPolicy
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.customerSupportPage {
  padding-top: 50px;
  padding-bottom: 50px;
  .title-h1 {
    color: #333333;
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
  }

  .title {
    margin-bottom: 1rem;
    h1 {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      font-size: 2.2rem;
      line-height: normal;
      color: #000;
    }
    p {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }
  .btn-border {
    width: 100%;
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid #dbdbdb;

    &:hover {
      border-color: $secondary;
      border-color: var(--q-color-secondary);
    }
  }
  .contentBox {
    border: 1px solid #dbdbdb;
    -webkit-border-radius: 9px;
    border-radius: 9px;
    .q-card__section {
      padding: 20px;
      position: relative;
      text-align: center;
    }
    h4 {
      padding-top: 12px;
      padding-bottom: 12px;
      color: #5e5c5c;
      font-size: 18px;
      font-weight: bold;
      line-height: normal;
    }
  }
  @media (max-width: 1023px) {
    padding-top: 25px;
    padding-bottom: 25px;

    .contentBox {
      height: auto !important;
    }
  }
}
</style>
